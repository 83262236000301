import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { IBlockContentProducts, IProduct } from "../../../../../../../interfaces";

import { ETaxTypes } from "../../../../../../../enums";

import { IBlockContentEditItem } from "../../BlockContentEdit";

import { Label, Row, Col, Input } from 'reactstrap'

import CustomDialog from "../../../../../../../components/UI/CustomDialog/CustomDialog";


import IconButton from "../../../../../../../components/UI/IconButton/IconButton";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";

import ReadCSVModal from "../../../../../../../components/ReadCSVModal/ReadCSVModal";

import { readString } from "react-papaparse";

import {
  controlsToFormGroups,
//	defaultInputChangedHandler,
} from "../../../../../../../shared/utility";

import CustomInput from './CustomInput';
import CustomInput2 from './CustomInput2';

import { calcTotal, calcTotalVat } from './functions';

import classes from "./BlockContentProductsEdit.module.scss";

//const rndStr = () => (Math.random().toString(36).substring(7));

const BlockContentProductsEdit: React.ForwardRefExoticComponent<
  IBlockContentEditItem
> = forwardRef((props, ref) => {
  const blockContent = props.blockContent as IBlockContentProducts;
	
  const [init, setInit] = useState(false);
  //  const [updateProductsKey, setUpdateProductsKey]=useState<string>(rndStr());

	const [jsonData, setJsonData]=useState<IBlockContentProducts>(blockContent)
	const [CSVProducts, setCSVProducts]=useState<IProduct[]>([])

	const [CSVModal, setCSVModal]=useState(false)
	const [CSVerror, setCSVerror]=useState("")
	const [pasteContent, setPasteContent]=useState("")

	const [searchProduct, setSearchProduct]=useState([...blockContent.products].filter((ob)=>ob.library===true).slice(0,500))
	const [searchString, setSearchString]=useState("")

	const [isOpenLibrary, setIsOpenLibrary]=useState(false);

	const [libraryProducts, setLibraryProducts]=useState<IProduct[]>([]);

  const updateControls = (data:IBlockContentProducts) => ({
    controls: {

			labels:{
            elementType: "input",
            elementConfig: {
              placeholder: "Field text",
              type: "custom"
            },

            touched: false,

						item:data.labels.item,
						quantity:data.labels.quantity,
						unit:data.labels.unit,
						unitPrice:data.labels.unitPrice,
						discount:data.labels.discount,
						vat:data.labels.vat,
						total:data.labels.total,

            custom:CustomInput2,
            
						onChange:inputChangedHandlerDef,
      },
			
			currency:{
					elementType:"input",
					elementConfig:{
						type:"text",
					},
					value:data.currency,
				config:{value:data.currency},
			},

			tax:{
					elementType:"radio",
					elementConfig:{
            options:[
							{value:ETaxTypes.EXCLUSIVE,displayValue:"Exclusive tax"},
							{value:ETaxTypes.INCLUSIVE,displayValue:"Inclusive tax"},
						],
					},
					value:data.tax || ETaxTypes.EXCLUSIVE,
				config:{value:data.tax},
			},

      showTotal:{
					elementType:"input",
					elementConfig:{
            type:"checkbox",
					},
					value:data.showTotal,
  				config:{value:data.showTotal},
      },

      color:{
					elementType:"input",
					elementConfig:{
						type:"color",
					},
					value:data.color,
				config:{value:data.color},

      },

			//products:Object.values(data.products).sort((a:any,b:any) => (a.order_nr>b.order_nr?1:-1)).reduce((resp:any,ob:IProduct,index:number)=>{
			products:Object.values(data.products).reduce((resp:any,ob:IProduct,index:number)=>{
        if(!ob.library) {
          	resp[index]={
          	   elementType: "input",
          	    elementConfig: {
          	      placeholder: "Field text",
          	      type: "custom"
          	    },

                touched: false,
                deleted:ob.deleted || false,

								item: ob.item || "",
								description: ob.description || "",
								quantity: ob.quantity || (ob.quantity === 0 ? 0 :""),
								unit: ob.unit || "",
								unitPrice: ob.unitPrice || (ob.unitPrice === 0 ? 0 :""),
								discount: ob.discount || (ob.discount === 0 ? 0 :""),
                vat: ob.vat || (ob.vat === 0 ? 0 :""),

                category: ob.category || "",

                order_nr: ob.order_nr || index,

								optional: ob.optional || false,
								optionalSelected: ob.optionalSelected || false,
								quantityEditable: ob.quantityEditable || false,

								math:{
										total:calcTotal(ob.unitPrice, ob.quantity || 0,ob.discount),
										totalVat:calcTotalVat(ob.unitPrice, ob.quantity || 0,ob.discount,ob.vat),
								},

          	    custom:CustomInput,
          	    index:index,

								onItemChange:inputItemChangedHandler,
								onChange:inputChangedHandler,
          	    onDelete:onDeleteHandler,
						}
				}
        return resp
      },{})
    },
  })

  const updateFields = (data:IBlockContentProducts) => {
    setJsonData(data)
	}

  const inputChangedHandlerCurrency = (
    event: React.ChangeEvent<HTMLInputElement>,
    controlName: string
  ) => {
 	 let value = event.target ? event.target.value : event;
		updateFields({...jsonData,currency:value as string})
	};

  const inputChangedHandlerTax = (
    event: React.ChangeEvent<HTMLInputElement>,
    controlName: string
  ) => {
 	 let value = event.target ? event.target.value : event;
		updateFields({...jsonData,tax:value as ETaxTypes})
  };

  const myInputChangedHandler = (field:string) => (
    event: React.ChangeEvent<HTMLInputElement>,
    controlName: string,
  ) => {
 	 let value = event.target ? event.target.value : event;
		updateFields({...jsonData,[field]:value})
  };

  const myInputCheckChangedHandler = (field:string) => (
    event: React.ChangeEvent<HTMLInputElement>,
    controlName: string,
  ) => {
    let value = event.target && event.target.checked || false;
		updateFields({...jsonData,[field]:value})
  };



	const inputChangedHandlerDef = (name:string, value: any) => {
		const p={...jsonData.labels}
		switch(name) {
			case "total":
			case "quantity":
			case "unitPrice":
			case "discount":
			case "vat":
				p[name]=value as string;
				break;

			case "unit":
			case "description":
			case "item":
				p[name]=value as string;
				break;
        /*
      case "currency": jsonData.currency=value;
        console.log("CUR")
        break;
         */
			default:
		}
		updateFields({...jsonData,labels:p})
  }

	const librarySearch = (e:any) => {
		const el=e.target as HTMLInputElement
		setSearchString(el.value);
		if(el.value.length==0) {
			const search=jsonData.products.filter((ob)=>ob.library===true).slice(0,500)
			setSearchProduct(search);
		}
		else {
			const search=jsonData.products.filter((ob)=>ob.library===true && ob.item && ob.item.toLowerCase().includes(el.value.toLowerCase()));
			setSearchProduct(search);
		}
	}

	useEffect(()=>{
		setLibraryProducts(jsonData.products.filter((ob)=>ob.library===true))
	},[jsonData.products]);

  const inputItemChangedHandler = (index: number, name:string, value: any) => {
    const p=[...jsonData.products]
    const s={...p[index]}
    s["item"]=value;
    p[index]=s;
		updateFields({...jsonData,products:p})
	}

  const inputChangedHandler = (index: number, name:keyof IProduct, value: any) => {
    const p=[...jsonData.products]
    const s:any={...p[index]}
		switch(name) {
			case "quantity":
			case "unitPrice":
			case "discount":
			case "vat":

				const v=value.replace(",",".")
				if(!isNaN(v)) {
					s[name]=v; //parseFloat(value);
				}
				break;

			case "description":
			case "unit":
			case "item":
			case "optional":
			case "optionalSelected":
			case "quantityEditable":
				s[name]=value;
				break;
			default:
    }

    p[index]=s;
    
    updateFields({...jsonData,products:p})
    updateControls({...jsonData,products:p})
  }

  const onDeleteHandler = (index: number) => {
		const p=[...jsonData.products]

		//const nr = p.findIndex((x:IProduct)=>x.order_nr == index);    
		//p[nr].deleted=true;
		
    p[index].deleted=true;

    updateFields({...jsonData,products:p})
  }

	const deleteLibrary = () => {
		const p=jsonData.products.filter((ob)=>!ob.library)
		updateFields({...jsonData,products:p})
	}

	const addFromLibrary = () => {
		setIsOpenLibrary(true)
	}
	const addProduct = (product:IProduct) => {
		const p=[...jsonData.products,{...product,library:false}];
		updateFields({...jsonData,products:p})
	}

	const addField = () => {
		const new_order_nr = jsonData.products.length>0?jsonData.products[jsonData.products.length-1].order_nr+1:0;
		const p=[...jsonData.products,
			{item:"",description:"",quantity:1,unit:"pcs",unitPrice:0,discount:0,vat:25.5, order_nr: new_order_nr}
		];
		updateFields({...jsonData,products:p});
  }

  const getData = () => {
    const products=jsonData.products.filter((ob)=>!ob.deleted);

		//const products = jsonData.products.filter((ob)=>!ob.deleted).sort((a:any,b:any) => ((a.order_nr || 0)>(b.order_nr || 0) ? 1:-1));

		const currency=jsonData.currency;
		const tax=jsonData.tax;
    const labels=jsonData.labels;
    const color=jsonData.color;
    const showTotal=jsonData.showTotal;

    return Object.assign(blockContent, {products,currency,tax,labels,color,showTotal});

	}

	const [controlsState, setControlsState] = useState(null) //updateControls(jsonData))


  useImperativeHandle(ref, () => ({
    getData
  }));
  
  useEffect(() => {
  		setControlsState(updateControls(jsonData))

		  if(props.onChange && init) {
        props.onChange(getData())
      } 
    // eslint-disable-next-line
  }, [jsonData]);


  useEffect(()=>{
    if(JSON.stringify(blockContent.products)!==JSON.stringify(jsonData.products)) {
      //clear defaultValues from input fields
      setControlsState(updateControls({...jsonData,products:[]}));

			//const sortedProducts = Object.values(blockContent.products).sort((a:any,b:any) => ((a.order_nr || 0)>(b.order_nr || 0) ? 1:-1));
			//setJsonData({...jsonData,products:sortedProducts});
      setJsonData({...jsonData,products:[...blockContent.products]});


      //setUpdateProductsKey(rndStr());

    }
  },[blockContent.products]);


	useEffect(() => {
		if(!init) {
			setInit(true);
			setControlsState(updateControls(jsonData));
		}
  }, [])

	const importCSVFile = () => {
		setCSVModal(true)
	}
	
	const saveFile = () => {
		setJsonData({...jsonData,products:[...jsonData.products,...CSVProducts]})

		const search=[...jsonData.products,...CSVProducts].filter((ob)=>ob.library===true).slice(0,500)
		setSearchProduct(search);
	}

	const parseFloat2 = (f:any) => {
		try {
			return parseFloat(f.replace(",","."))
		} catch(err) {
			return parseFloat(f)
		}
	}

	const handleFile = (csv_content:string, save_instantly:boolean=false) => {
		const x:any = {header:true, skipEmptyLines:true};
		const d:any=readString(csv_content,x);
		//console.log(d.data);
		const tempdata:Array<IProduct>=[];

		d.data.forEach((ob:any,index:number)=>{
			//	Active: "Yes"
			//	Category: "kategoria1"
			//	Description: ""
			//	ID: "1"
			//	Name: "tuote1"
			//	Owner: "Seppo Tillanen"
			//	Price (EUR): "12"
			//	Product code: "123"
			//	Tax: "24"
			//	Unit: "kpl"
			//	Unit prices: "12 EUR"
			//	Visible to: "owner's visibility group"
				if(index<1000-jsonData.products.filter((ob)=>ob.library).length) {
          //					tempdata.push({library:true,item:(ob["Product code"]?ob["Product code"]+" ":"")+ob.Name+(ob["Description"]?"\n"+ob["Description"]:""),description:"",quantity:1,unit:ob.Unit,unitPrice:parseFloat2(ob.Price),discount:0,vat:parseFloat2(ob.Tax)})
          tempdata.push({library:true,item:(ob["Product code"]?ob["Product code"]+" ":"")+ob.Name,description:ob["Description"],quantity:1,unit:ob.Unit,unitPrice:parseFloat2(ob.Price),discount:0,vat:parseFloat2(ob.Tax)})

				}
				if(ob["Add"]>0) {
          //         tempdata.push({library:false,item:(ob["Product code"]?ob["Product code"]+" ":"")+ob.Name+(ob["Description"]?"\n"+ob["Description"]:""),description:"",quantity:parseFloat2(ob["Add"]),unit:ob.Unit,unitPrice:parseFloat2(ob.Price),discount:0,vat:parseFloat2(ob.Tax)})
					tempdata.push({library:false,item:(ob["Product code"]?ob["Product code"]+" ":"")+ob.Name,description:ob["Description"],quantity:parseFloat2(ob["Add"]),unit:ob.Unit,unitPrice:parseFloat2(ob.Price),discount:0,vat:parseFloat2(ob.Tax)})

				}

			//console.log(ob);
		})

		//		updateFields({...jsonData,products:[...jsonData.products,...tempdata]})
		setCSVProducts(tempdata);

		if(tempdata.length<d.data.length) {
			setCSVerror("total products in file "+d.data.length+" limited to 1000 items")
		}
		else {
			setCSVerror("")
		}

		if(save_instantly) {
			const p=[...jsonData.products,...tempdata];
			updateFields({...jsonData,products:p})
/*
			setJsonData({...jsonData,products:[...jsonData.products,...tempdata]})

			const search=[...jsonData.products,...tempdata].filter((ob)=>ob.library===true).slice(0,100)
			setSearchProduct(search);
*/			
		}

	}

  let blurHandler:any = null;
    if(props.onBlur) {
			blurHandler = () => {
        props.onBlur(getData());
      }
    }

	if(!controlsState) return null;

	let total=0;
	let totalVat=0;
	const totals:any=Object.values(controlsState.controls.products).reduce((resp:any,ob:any)=>{
		if(!ob.library && !ob.deleted && (!ob.optional || ob.optionalSelected)) {
			if(controlsState.controls.tax.value===ETaxTypes.INCLUSIVE) {
				const t=ob.math.total-ob.math.total/(1+ob.vat/100);
				resp[ob.vat]=(resp[ob.vat] || 0) + t;
				total+=ob.math.total-t;
				totalVat+=t;
			}
			else {
				const t=ob.math.total*ob.vat/100;
				resp[ob.vat]=(resp[ob.vat] || 0) + t;
				total+=ob.math.total;
				totalVat+=t;
			}
		}
		return resp
	},{})


	const labels=controlsState.controls.labels;

  return (
		<React.Fragment>
			<Label>{blockContent.name}</Label>

			<Row>
				<Col xs={3}>
					Currency
				</Col>
				<Col>
		      {controlsToFormGroups({currency:controlsState.controls.currency}, inputChangedHandlerCurrency, blurHandler)}
				</Col>
				<Col>
		      {controlsToFormGroups({tax:controlsState.controls.tax}, inputChangedHandlerTax, blurHandler)}
				</Col>

			</Row>

      {/*controlsToFormGroups({labels:controlsState.controls.labels}, inputChangedHandlerDef, blurHandler)*/}
      {controlsToFormGroups({labels:controlsState.controls.labels}, null, blurHandler)}

		{/*
			<Row>
				<Col className={classes.hCol}>{labels.item}</Col>
				<Col className={classes.hCol} xs={1}>{labels.unitPrice}</Col>
				<Col className={classes.hCol} xs={1}>{labels.quantity}</Col>
				<Col className={classes.hCol} xs={1}>{labels.unit}</Col>
				<Col className={classes.hCol} xs={1}>{labels.vat}</Col>
				<Col className={classes.hCol} xs={1}>{labels.discount}</Col>
				<Col className={classes.hCol} xs={1}>{labels.total}</Col>
				<Col className={classes.hCol} xs={1}></Col>

			</Row>
		*/}
    {/*controlsToFormGroups(controlsState.controls.products, inputChangedHandler, blurHandler)*/}

      {controlsToFormGroups(controlsState.controls.products, null, blurHandler)}



      <Row>
        <Col style={{display:"flex"}}>
         <IconButton
          style={{ marginTop: "1rem", fontWeight: 500 }}
          color="light"
          icon={faPlus}
          onClick={()=>addField()}
        >
          Add a new line
        </IconButton>

				{libraryProducts.length>0 &&
					<IconButton
	          style={{ marginLeft: "1rem", marginTop: "1rem", fontWeight: 500 }}
          	color="light"
        	  icon={faPlus}
      	    onClick={()=>addFromLibrary()}
    	    >
  	        Add from library ({libraryProducts.length})
				</IconButton>}

				</Col>
      </Row>


  		<Row>
				<Col className="text-right">{labels.total} ({labels.vat} 0%)</Col>
				<Col xs={2} className="text-right">
					{total.toFixed(2)}
				</Col>
				<Col xs={1}>{controlsState.controls.currency.config.value}</Col>
			</Row>

			{Object.keys(totals).map((key:any)=>{
				const ob=totals[key];
				return(<Row key={key}>
					<Col className="text-right">{labels.vat} {key}%</Col>
					<Col xs={2} className="text-right">{ob.toFixed(2)}</Col>
					<Col xs={1}>{controlsState.controls.currency.config.value}</Col>
				</Row>)
			})}
  		
			<Row>
				<Col className="text-right">{labels.total}</Col>
				<Col xs={2} className="text-right">
					{(total+totalVat).toFixed(2)}
				</Col>
				<Col xs={1}>{controlsState.controls.currency.config.value}</Col>
      </Row>
      <hr/>
			<Row>
				<Col xs={6}>
          Header and grid Color
		      {controlsToFormGroups({color:controlsState.controls.color}, myInputChangedHandler("color"), blurHandler)}

				</Col>
        <Col xs={6} className="text-right">
          <div>
          Show Totals
          <div style={{"marginLeft":"1.25rem"}}>
            {controlsToFormGroups({showTotal:controlsState.controls.showTotal}, myInputCheckChangedHandler("showTotal"), blurHandler)}
          </div>
        </div>

				</Col>

			</Row>



      <Row>
        <Col style={{display:"flex"}}>

			{libraryProducts.length>0 &&
					<IconButton
	          style={{ marginRight: "1rem", marginTop: "1rem", fontWeight: 500 }}
          	color="light"
        	  icon={faTrash}
      	    onClick={()=>deleteLibrary()}
    	    >
  	        Delete library ({libraryProducts.length})
	        </IconButton>}

      <IconButton
          style={{ marginTop: "1rem", fontWeight: 500 }}
          color="light"
          icon={faPlus}
          onClick={()=>importCSVFile()}
      >
          Import Products from CSV -file
			</IconButton>
			</Col>
			<Col>
				<Input type="textarea" onChange={(evt)=>setPasteContent(evt.target.value)} value={pasteContent}/>

			</Col>
			<IconButton onClick={()=>{handleFile(pasteContent, true);}} icon={faPlus} color="light">import</IconButton>
		</Row>



			<ReadCSVModal
				showModal={CSVModal}
				toggleModal={()=>{setCSVModal(false);setCSVProducts([])}}
				handleFile={handleFile}
				saveFile={saveFile}
				data={CSVProducts}
				csverror={CSVerror}
			/>

			<CustomDialog
				open={isOpenLibrary}
				onCancel={()=>setIsOpenLibrary(false)}
				title={"Product library"}
			>
				<Input
					onChange={librarySearch}
					value={searchString}
					placeholder="Search products..."
				/>
					{searchProduct.map((ob)=>{
					return(<div className={classes.libraryItem} key={ob.item} onClick={()=>addProduct(ob)}>{ob.item}</div>)
				})}

			</CustomDialog>
    </React.Fragment>
  );
});

  export default BlockContentProductsEdit;
