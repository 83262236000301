import React, { useEffect, useState, ReactNode, CSSProperties, useContext } from "react";
import WebFont from "webfontloader";

import { EUserTemplateBlockType, EContentType, EOfferStatus } from "../../../../../../enums";
import {
  ELayoutType,
  IBlockContent,
  IOffer,
  IStyles,
  IUserTemplateArea,
  IUserTemplateAreaBlock,
  IUser
} from "../../../../../../interfaces";
import {
  getBlockStyles,
  getBlockContentStyles
} from "../../../../../../shared/styles";
import { addElementStyles, showBlock } from "../../../../../../shared/utility";
import OfferPreviewContext from "../../../../containers/Offers/Offer/OfferPreviewContext";
import BlockContentShow from "../../../Blocks/Block/BlockContentShow";

import OfferContext from "../../../../containers/Offers/Offer/OfferContext";

import classes from "./OfferPreview.module.scss";

interface IProps {
  offer: IOffer;
  isVisible: boolean;
  showDummyContent?: boolean;
  token?: string;
  printable?: boolean;
	currentUser?: IUser;
	strongauth?: boolean;
}

const OfferPreview: React.FC<IProps> = ({
  offer,
  isVisible,
  showDummyContent,
  token,
  printable,
	currentUser,
	strongauth,
}) => {
  const [loadedFonts, setLoadedFonts] = useState([]);

  const loadFont = (font: string, newLoadedFonts: Array<string>) => {
    if (font && newLoadedFonts.indexOf(font) === -1) {
      WebFont.load({
        google: {
          families: [`${font}:400,700&display=swap`]
        }
      });
      newLoadedFonts.push(font);
    }
	};


  const { onSimpleContentChanged } = useContext(OfferContext);


  useEffect(() => {
    if (offer.userTemplate) {
      const { styles } = offer.userTemplate;
      if (styles) {
        const newLoadedFonts = [...loadedFonts];
        loadFont(styles.titleFont, newLoadedFonts);
        loadFont(styles.paragraphFont, newLoadedFonts);
        setLoadedFonts(newLoadedFonts);

        addElementStyles(
          "offer-preview",
          styles.titleFont,
          styles.paragraphFont,
          styles.titleColor,
          styles.paragraphColor,
          styles.linkColor,
          offer.userTemplate.customColor
        );
      }
    }
    // eslint-disable-next-line
  }, [offer.userTemplate]);

  if (!offer) return null;

  const { userTemplate } = offer;

  if (!userTemplate) return null;

  const renderContent = (
    content: Array<IBlockContent>,
    key: string,
		styles: CSSProperties,
		block?:IUserTemplateAreaBlock,
	): ReactNode => {
    if (!content || !content.length) return null;

    let isEmpty = false;

    var retval = 
      <div className={classes.BlockContent} key={key}>
				{content.map(blockContent => {
          let contentStyles = { ...styles };
          if (blockContent.contentType === EContentType.PRICING) {
            contentStyles = {};
          }
          if (blockContent.contentType === EContentType.FIELDS) {
            contentStyles = {};
          }

          if(blockContent.contentType === "PRIVATE2") {
            const _data:any = JSON.parse(blockContent.json);
            if(_data.section1.length === 0 && _data.section2.length === 0) isEmpty = true;
          }

          return (
            <div style={contentStyles} key={blockContent.id}>
              <BlockContentShow
                blockContent={blockContent}
								showDummyContent={showDummyContent}
								onChange={(c)=>onSimpleContentChanged(block,c)}
								offer={offer}
              />
            </div>
          );
        })}
      </div>;

    if(isEmpty) return null;
    return retval;
  };

  const renderBlockContent = (block: IUserTemplateAreaBlock) => {
    switch (block.blockType) {
      case EUserTemplateBlockType.DYNAMIC:
        if (block.offerBlockItems) {
          return block.offerBlockItems.map(blockItem => {
						if(blockItem) {
	            return renderContent(
  	            blockItem.content,
    	          blockItem.id,
								getBlockContentStyles(block.styles),
								block,
        	    );
						}
						else {
							//console.log("ERROR");
							return ""
						}
          });
        }
        break;
      case EUserTemplateBlockType.SIMPLE:
        return renderContent(
          block.content,
          null,
					getBlockContentStyles(block.styles),
					block,
        );
      default:
        return null;
    }
  };

  const addStylesGetClassName = (id: string, styles: IStyles) => {
    let className = "";
    if (styles) {
      className = styles.cssClassName;
      // FIXME: breaks font styles on user template
      addElementStyles(
        id,
        null,
        null,
        // styles.titleFont,
        // styles.paragraphFont,
        styles.titleColor,
        styles.paragraphColor,
        styles.linkColor,
        null
      );
    }
    return className;
  };

  const hideBlock = (block: IUserTemplateAreaBlock) => {

    // With token -> offer accept
    if (token && block.isEmpty) return true;
    // Without token -> offer
    if (!token) return !showBlock(block);
    return false;
  };

  const getSentDate = () => {
    if(!offer || !offer.history) return null;
    //const sentHistory = offer.history.find(h => h.offerStatus === EOfferStatus.SENT);
    const sentHistory = offer.history.filter(h => h.offerStatus === EOfferStatus.SENT).sort((a,b)=>(a.createdISO>b.createdISO?1:-1)).pop();
    if(!sentHistory) return null;
    return sentHistory.created;
  }

  const renderBlocks = (blocks: Array<IUserTemplateAreaBlock>) => {
    if (!blocks) return null;
		return blocks.map((_block, index) => {
      let block = {..._block};

      if(block.name === 'DYNAMIC PDF') {
      }      
      else if (hideBlock(block)) return null;

      const id = `block-${block.id}`.replace(/=/g,"");
			let className = addStylesGetClassName(id, block.styles);


			// REMOVE BLOCKS if strongauth = true
			if(strongauth) {
				const strongRemove=(block.content || []).filter(content => ["LEGAL_BOX","SIGNATURE"].includes(content.contentType)).length;

				// should this be handled ??
				// console.log("obi",block.offerBlockItems)

				if(strongRemove>0) return null;
			}

		
      const isEmpty = (block.content || []).reduce((resp, blockContent) => {
          if(blockContent.contentType === "PRIVATE2") {
            const _data:any = JSON.parse(blockContent.json);
            if((_data.section1 || []).length === 0 && (_data.section2 || []).length === 0) return true;
          }
          return resp;
      },false);
      if(isEmpty) return null;


      return (
        <div
          key={block.id}
          style={{ ...getBlockStyles(block.styles) }}
          className={className}
          id={id}
        >
          {block.showName && (
            <h3 className={"offer-titles "+classes.BlockName}>{block.name}</h3>
          )}
          <div
            className={
              block.layoutType === ELayoutType.HORIZONTAL
                ? classes.Horizontal
                : classes.Vertical
            }
          >
            <OfferPreviewContext.Provider
              value={{
                customer: offer.customer,
                organization: offer.organization,
                hash: offer.hash,
                confirmDate: offer.confirmDate,
                status: offer.status,
                id: offer.id,
                token,
                hideAuditLogs: offer.hideAuditLogs,
                printable,
                sentDate: getSentDate(),
                customerSignature: offer.customerSignature,
                author: offer.author,
                currentUser,
              }}
            >
              {renderBlockContent(block)}
            </OfferPreviewContext.Provider>
          </div>
        </div>
      );
    });
  };

  const renderArea = (area: IUserTemplateArea, id: string) => {
    if (!area) return null;

    let className = addStylesGetClassName(id, area.styles);
    return (
      <div
        id={id}
        className={className}
        style={{ overflow: "auto", ...getBlockStyles(area.styles) }}
      >
        {renderBlocks(area.blocks)}
      </div>
    );
  };
  return (
    <div
      className={classes.Container}
      style={{
        //padding: printable?0:"3rem",
        display: isVisible ? "block" : "none",
        ...getBlockStyles(userTemplate.styles)
      }}
      id="offer-preview"
    >
      <div className={classes.Content}>
        {renderArea(userTemplate.header, "offer-preview-header")}
        {renderArea(userTemplate.main, "offer-preview-main")}
        {renderArea(userTemplate.footer, "offer-preview-footer")}
      </div>
    </div>
  );
};

export default OfferPreview;
