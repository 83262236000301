import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import mixpanel from 'mixpanel-browser';
import React, { useState } from "react";
//import Intercom from 'react-intercom';
import { NavLink as RouterNavLink } from "react-router-dom";
import { Collapse, DropdownItem, DropdownMenu, DropdownToggle, Nav, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink, UncontrolledDropdown } from "reactstrap";
//import { INTERCOM_APP_ID } from "../..";
import Roles from "../../enums/user-roles";
import { IUser } from "../../interfaces";
import { getApiPath, hasAccess } from "../../shared/utility";

import preval from 'preval.macro';

import classes from "./Navigation.module.scss";

interface IProps {
  currentUser: IUser;
  onLogout: () => void;
  navItems: Array<{ text: string; link: string, number?: string }>;
  dropdownItems: Array<{ text: string; link: string }>;
}

const Navigation: React.FC<IProps> = ({ currentUser, onLogout, navItems, dropdownItems }) => {
  const [isOpen, setOpen] = useState(false);

  if (!currentUser) return null;

  //if (dropdownItems && hasAccess(currentUser, Roles.ADMIN, Roles.TEAM_LEAD, Roles.ENTREPRENEUR, Roles.SUPERADMIN)) {
  if (dropdownItems && hasAccess(currentUser, Roles.ADMIN, Roles.ENTREPRENEUR, Roles.SUPERADMIN)) {
    dropdownItems = [
      { link: "/users", text: "Users" },
      { link: "/organization", text: "Organization" },
      { link: "/billing", text: "Billing" },
      { link: "/integrations", text: "Integrations" },
      ...dropdownItems
    ];
  }

  //if (navItems && hasAccess(currentUser, Roles.ADMIN, Roles.TEAM_LEAD, Roles.ENTREPRENEUR) && !currentUser.isRestricted) {
  if (navItems && hasAccess(currentUser, Roles.ADMIN, Roles.ENTREPRENEUR) && !currentUser.isRestricted) {
    navItems = [
      ...navItems,
      { link: "/templates", text: "Templates" }
    ];
  }

  const onToggle = () => {
    setOpen(!isOpen);
  };

  let user = null;
  
  if(currentUser) {
    /*
    mixpanel.identify(currentUser.id);
    
    mixpanel.track("Page change", {
      "page": window.location.pathname
    });
    //}, () => console.log(`Page changed to ${window.location.pathname}`));
    */
    user = {
      user_id: currentUser.legacyId, //email, //id,
      email: currentUser.email,
      name: currentUser.name,
      created_at: currentUser.createdUnixTime
    };
  }
  
  const buildtime=preval`module.exports = new Date().toISOString();`;

  return (
    <Navbar light expand="md" className={classes.NavBar}>
      <NavbarBrand tag={RouterNavLink} to="/offers/funnel">
        C2C
      </NavbarBrand>
      <NavbarToggler onClick={onToggle} />
      <Collapse isOpen={isOpen} navbar>
        <Nav className="mr-auto" navbar>
          {navItems &&
            navItems.map(navItem => (
              <NavItem key={navItem.text}>
                <NavLink tag={RouterNavLink} to={navItem.link}>
                  {navItem.text}
                  { /* navItem.number ? <CustomLabel type={ECustomLabelType.CIRCLE} text={navItem.number} /> : null */}
                </NavLink>
              </NavItem>
            ))}
        </Nav>
        <Nav className="ml-auto" navbar>
          <UncontrolledDropdown nav inNavbar>
            <DropdownToggle nav caret style={{padding: '0 1rem'}}>
              {currentUser.imageURL ?
                <img
                  className={classes.UserImage}
                  alt=""
                  src={getApiPath() + 'tools/images/' + currentUser.imageURL}
                />
                : null}
              {currentUser.name}
            </DropdownToggle>
            <DropdownMenu right>
              {dropdownItems &&
                dropdownItems.map(navItem => (
                  <DropdownItem
                    key={navItem.text}
                    tag={RouterNavLink}
                    to={navItem.link}
                  >
                    {navItem.text}
                  </DropdownItem>
                ))}
                <DropdownItem onClick={onLogout}>Logout</DropdownItem>
                <DropdownItem divider />
                <DropdownItem header className="text-center">Click2Contract<br/>{buildtime}</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
      </Collapse>
      {/*<Intercom appID={INTERCOM_APP_ID} {...user} />*/}
    </Navbar>
  );
};

export default Navigation;
